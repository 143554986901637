<template>
  <div>
    <Defaultlayout :userprofile="userprofile">
      <section class="food-fast-main">
        <!-- searchbar -->
        <div class="search-bar-filter">
          <div class="container">
            <div class="search-bar">
              <form @submit.prevent="pstGetSearch">
                <div class="form-row">
                  <div class="form-group mb-0">
                    <label for="staticEmail2" class="sr-only">search</label>
                    <input
                      type="text"
                      class="form-control"
                      id="staticEmail2"
                      placeholder="Search for item / Category / Store"
                      v-model="searchqry"
                    />
                  </div>
                  <button
                    :style="colorObj"
                    type="button"
                    class="btn button-style hoverEffect"
                    @click="pstGetSearch"
                    :disabled="!searchqry"
                  >
                  <span class="d-md-none">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="15px" height="15px" viewBox="123.295 0 25.001 25" enable-background="new 123.295 0 25.001 25" xml:space="preserve">
                    <g>
                      <g>
                        <g>
                          <path fill="currentColor" d="M134.303,0c-6.069,0-11.008,4.938-11.008,11.01c0,6.07,4.938,11.01,11.008,11.01
                            c6.072,0,11.012-4.938,11.012-11.01C145.314,4.938,140.375,0,134.303,0z M134.303,19.986c-4.948,0-8.977-4.027-8.977-8.978
                            s4.028-8.978,8.977-8.978c4.951,0,8.979,4.027,8.979,8.978C143.279,15.959,139.254,19.986,134.303,19.986z"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path fill="currentColor" d="M147.997,23.266l-5.827-5.828c-0.396-0.396-1.041-0.396-1.436,0c-0.398,0.398-0.398,1.041,0,1.438
                            l5.824,5.826C146.76,24.9,147.018,25,147.279,25c0.259,0,0.52-0.1,0.718-0.298C148.395,24.307,148.395,23.662,147.997,23.266z"/>
                        </g>
                      </g>
                    </g>
                    </svg>
                  </span>
                  Search </button>

                  <div class="filter d-flex flex-wrap">
                    <span class="filter-btn" v-b-toggle.my-collapse>
                      <img src="../../assets/images/svgs/filter.svg" alt="filter" class="filter-img" />
                    </span>
                    <b-collapse id="my-collapse" class="category-tab">
                      <ul
                        class="nav nav-pills mb-3"
                        role="tablist"
                        v-if="advncefiltrlist.status=='1'"
                        id="pills-tab"
                      >
                        <li
                          role="presentation"
                          v-for="(fltrlist,index) in advncefiltrlist.data"
                          v-bind:key="index"
                          class="nav-item"
                        >
                          <a
                            :class="(index==0) ? 'nav-link active':'nav-link'"
                            id="pills-store-tab"
                            data-toggle="pill"
                            :href="'#'+fltrlist.key"
                            role="tab"
                            aria-controls="pills-store"
                            aria-selected="true"
                          >{{fltrlist.name}}</a>
                        </li>
                      </ul>
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          v-for="(nwfltrlist,index) in advncefiltrlist.data"
                          v-bind:key="index"
                          :class="(index==0) ? 'tab-pane fade show active':'tab-pane fade show'"
                          :id="nwfltrlist.key"
                          role="tabpanel"
                          :aria-labelledby="nwfltrlist.key"
                        >
                          <ul
                            class="categary-detail list-unstyled"
                            v-if="nwfltrlist.key=='store_type' || nwfltrlist.key=='online_order'"
                          >
                            <li
                              v-for="(newvl,index) in nwfltrlist.value"
                              v-bind:key="index"
                              @click="getActmenu(nwfltrlist.key,newvl.id,nwfltrlist.id);"
                              :id="'new'+nwfltrlist.id+newvl.id"
                              style="cursor:pointer;"
                              :class="(index == 0) ? 'active': ''"
                            >
                              <a class="d-flex">
                                {{newvl.name}}
                                <span class="right-skyblue">
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="13.135px" height="10px" viewBox="0 0 13.135 10" enable-background="new 0 0 13.135 10" xml:space="preserve">
                                    <polygon fill="#3AC6F4" points="4.179,7.91 1.044,4.776 0,5.821 4.179,10 13.135,1.044 12.089,0 "/>
                                  </svg>
                                </span>
                              </a>
                            </li>
                          </ul>
                            <ul
                            class="categary-detail list-unstyled"
                            v-if="nwfltrlist.key=='delivery_type'"
                          >
                            <li
                              v-for="(newvl,index) in nwfltrlist.value"
                              v-bind:key="index"
                              @click="getActmenu(nwfltrlist.key,newvl.id,nwfltrlist.id);"
                              :id="'new'+nwfltrlist.id+newvl.id"
                              style="cursor:pointer;"
                              :class="(index == 0 || index == 1) ? 'active': ''"
                            >
                              <a class="d-flex">
                                {{newvl.name}}
                                <span class="right-skyblue">
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="13.135px" height="10px" viewBox="0 0 13.135 10" enable-background="new 0 0 13.135 10" xml:space="preserve">
                                    <polygon fill="#3AC6F4" points="4.179,7.91 1.044,4.776 0,5.821 4.179,10 13.135,1.044 12.089,0 "/>
                                  </svg>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <!-- Sponsored Stores start -->
        <section class="search-page sponsored-stores" :class="showMapViewAfterSearch ? 'nearby' :''">
          <div class="container">
            <div v-if="loading==true">
              <p class="text-center">
                <img src="../../assets/images/loading.gif" />
              </p>
            </div>
            <div class="sectionheadingupper d-flex flex-wrap justify-content-between align-items-center">
            <h2
              class="section-heading"
              v-if="storelist.status=='1' && loading==false"
            >{{search_result_name}}</h2>
            <span class="viewModeSwitch d-inline-block" v-if="storelist.status=='1' && use_google_map == 1">
              <a v-if="showMapViewAfterSearch" href="javascript:void(0)" class="btn-normal" @click="showGridView()">View All</a>
              <a v-if="showGridViewAfterSearch" href="javascript:void(0)" class="btn-normal" style="margin-left:5px" @click="showMapView()">Map View</a>
            </span>
            </div>
            <div class="row advance-search-row" :class="showMapViewAfterSearch ? 'mapView':''" v-if="storelist.status=='1'">
              <span class="col-md-12" v-if="showMapViewAfterSearch">
               <div class="row map-row">
                <div class="col-md-5 col-lg-3">
                <div class="store-list">
                  <div class="shop-details" @click="FocusStore(store.store_id,index)" :class="selected_active_store == index ? 'active-shop':''"  v-for="(store,index) in storelist.data" v-bind:key="index">
                  <div class="store-profile media">
                  <div class="image">
                    <img v-if="store.store_logo !=''" :src=store.store_logo alt="..">
                    <avatar v-else :fullname="store.store_name.charAt(0)" :size="200" :radius="0" :color="avatarBackgroundColor"></avatar>
                  </div>
                  <div class="media-body">
                    <h3 :title="store.store_name">{{strippedItemname(store.store_name)}}</h3>
                    <div class="row mx-0">
                    <ul class="list-unstyled shop-catagory">
                      <li class="category-sec">
                        <a href="javascript:void(0)" @click="getStoreByCategory(store)"><img :src="store.category_black_icon" alt="store category"></a>
                      </li>
                      <li class="whislist" v-if="store.is_favourite == 1">
                        <img src="../../assets/images/svgs/whishlist-icon.svg" alt="favourite store">
                      </li>
                      <li class="khata" v-if="store.is_khata == 1">
                        <img src="../../assets/images/svgs/khata-icon.svg" alt="khata store">
                      </li>
                      <li class="wholesaler-icon" v-if="store.is_whole_seller == 1">
                          <img src="../../assets/images/svgs/ic_wholesaller.svg" alt="wholesaler tile">
                      </li>      
                     </ul>
                     <div class="rating-status media align-items-center" v-if="(review_rating_module == 1) ? (store.avg_rating != null && show_review_rating == 1):false">
                      <img src="../../assets/images/svgs/star-black.svg" alt="...">
                      <div class="media-body">{{store.avg_rating}}</div>
                     </div>
                  </div>
                  </div>
                </div>
                <div class="address media" v-show="store.store_address != ''">
                  <div class="icon mr-1">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-geo-alt-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    </svg>
                  </div>
                  <div class="media-body">
                    <p>{{store.store_address}}</p>
                  </div>
                </div>
                <div class="distance row mx-0" v-show="store.distance != ''">
                  <div class="media align-items-center">
                    <div class="icon mr-1">
                      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-geo-alt-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                      </svg>
                    </div>
                    <div class="media-body"> {{store.distance}} </div>
                  </div>
                </div>
                <div class="call-number media align-items-center">
                  <div class="icon mr-1">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-telephone-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"/>
                    </svg>
                  </div>
                  <div class="media-body">
                      <a class="number" :href="'tel:'+store.phone">{{store.phone}}</a>      
                  </div>
                    <a href="#" class="map-btn" @click="ViewStore(store.store_id)" >Shop Now</a>
                </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-lg-9 px-md-0">
              <GmapMap
                :center="{lat:parseFloat(map_center_latitude == null ? 23.0225 : map_center_latitude), lng:parseFloat(map_center_longitude == null ? 72.5714 : map_center_longitude)}"
                map-type-id="terrain"
                :zoom="18"
                class="mapSignUp"
                ref="mmm"
                id="map"
              >
                <GmapMarker
                  :key="index"
                  :v-if="markers.length > 0"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :icon="{ url: require('../../assets/images/StoreCustomMarkerIcon.png'),scaledSize: {width: 25, height: 35}}"
                  @click="toggleInfoWindow(m,index)"
                />

                <gmap-info-window 
                  @closeclick="window_open=false" 
                  :opened="window_open" 
                  :position="infowindow"
                  :options="infoOptions"
                >
                    {{selected_location_name}}
                </gmap-info-window>   
              </GmapMap>
              </div>
              </div>
              </span>
              <div class="col-custom" v-show="showGridViewAfterSearch" v-for="(store,index) in storelist.data" v-bind:key="index">
                <div class="shop-main" @click="shopRedirection(store.store_id,store.search_item_count)">
                  <router-link to="" class="shop-image">
                    <img v-if="store.store_logo!=''" v-bind:src="store.store_logo" alt="product">
                    <avatar v-else :fullname="store.store_name.split(' ')[0]" :radius="0" :color="avatarBackgroundColor"></avatar>
                    <span v-if="store.is_open == 1" class="oder-status bg-green">Order Now</span>
                    <span v-else class="oder-status bg-red">Closed Now</span>
                    <span class="rating-status" v-if="(review_rating_module == 1) ? (store.avg_rating != null && show_review_rating == 1) : false">
                      <img src="../../assets/images/svgs/star-black.svg" alt="rating">
                      <span>{{store.avg_rating}}</span>
                    </span>
                  </router-link>
                  <div class="shop-details">
                    <ul class="list-unstyled shop-catagory">
                      <li class="category-sec">
                        <a href="javascript:void(0)" @click="getStoreByCategory(store)"><img :src="store.category_black_icon" alt="store category"></a>
                      </li>
                      <li class="whislist" v-if="store.is_favourite == 1">
                        <img src="../../assets/images/svgs/whishlist-icon.svg" alt="favourite store">
                      </li>
                      <li class="khata" v-if="store.is_khata == 1">
                        <img src="../../assets/images/svgs/khata-icon.svg" alt="khata store">
                      </li>
                      <li class="wholesaler-icon" v-if="store.is_whole_seller == 1">
                          <img src="../../assets/images/svgs/ic_wholesaller.svg" alt="wholesaler tile">
                      </li>                      
                    </ul>
                    <h5 class="advance-search-store-name">{{store.store_name | strippedStorename}}</h5>
                    
                    <p class="distance">{{ store.distance }}</p> 
                  </div>
                </div>
              </div>
            </div>
            
            <div v-if="storelist.status=='0' && loading==false">
              <div class="empty-box text-center">
                <div class="contents">
                  <div class="empty-image search-not-found">
                    <img src="../../assets/images/svgs/search-not-found.svg" alt="search-not-found">
                  </div>
                <h3>{{storelist.message}}</h3>
                </div>
                <a href="/" :style="colorObj" class="btn button-style btn-large hoverEffect">Go to Home</a>
              </div>
            </div>
          </div>
        </section>
      </section>
    </Defaultlayout>
  </div>
</template>
<script>
import { bus } from "../../main";
import Avatar from "vue-avatar-component";
import Defaultlayout from "../../layouts/Defaultlayout";
import helper from "../../store/helper";
export default {
  name: "AdvanceSearch",
  props:{
    iscustomtitle:{
      type:String,
    }
  },
  components: {
    Defaultlayout,
    Avatar
  },
  mixins:[helper],
  data() {
    return {
      map_center_latitude:null,
      map_center_longitude:null,
      userprofile: null,
      searchqry: "",
      custsearchbody: {
        user_id: "",
        search_query: "",
        lat: "",
        long: "",
      },
      storetype: "",
      deliverytype: "",
      onlineorder: "",
      advncefiltrlist: [],
      loading: false,
      storelist: [],
      storetypearr: [0],
      deliveryarr: [0,1],
      onlineordrarr: [0],
      def: [],
      custadvncenwsearch: {
        user_id: "",
        search_query: "",
        lat: "",
        long: "",
        store_type: "",
        delivery_type: "",
        online_order: ""
      },
      category: {
        shop_category_id: "",
        shop_category_name: "",
        type: "",
      },
      markers:[],
      location_addresses : [],
      showGridViewAfterSearch : true,
      showMapViewAfterSearch : false,
      show_review_rating : 1,
      review_rating_module : 1,
      initializeGoogleMap : {
       streetViewControl: true,
       scaleControl: true,
       center: { lat: 34.04924594193164, lng: 34.04924594193164 },
       zoom: 2,
      },
      selected_active_store : 0,
      infowindow: {lat: 34.04924594193164, lng: 34.04924594193164},
      window_open: false,
      selected_location_name : '',
      infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35
          }
      },
      location_hash:'',
      favrtebody : {
        user_id: "",
        store_id: "",
        is_favourite: "",
        near_by:"",
        lat:"",
        long:"",
        area_id:"",
        pincode:""
      },
      search_result_name : "Search Results",
      avatarBackgroundColor:"",
      avatarTextColor:"",
      use_google_map:"",
      previousURL:null,
      default_rating_bg_color:"",
      default_rating_color_code_text:"",
      show_rating_different_color_code: 0,
      alternet_bg_color:"",
      alternate_color_code_text:""
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
        vm.previousURL = from;
        window.previousURL = from.name;
        localStorage.setItem("PREVIOUS_URL", from.name);
    });
  },
  filters: {
    strippedStorename: function(value) {
      if (value.length > 17) {
        return value.slice(0, 14) + "...";
      }
      return value;
    },
    strippedCategoryName: function(value) {
      if (value.length > 15) {
        return value.slice(0, 12) + "...";
      }
      return value;
    },
  },
  mounted() {
    this.location_hash = location.hash;
    if (localStorage.getItem("user") != null) {
        this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
        this.userprofile = null;
    }

    bus.$on("searchQry", data => {
      localStorage.setItem("searchqry", data);
      this.searchqry = localStorage.getItem("searchqry");
    
    });

    this.def = localStorage.getItem("searchqry");

    if (this.def != "") {
      this.searchqry = this.def;
    }
    
      localStorage.setItem("home-search",this.searchqry)
    if (this.searchqry != "") {
      this.pstGetSearch();
    }
    this.getAdvnceFltrList();
    window.$('<style>.category-tab .nav-pills .nav-link.active { color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.category-tab .nav-pills .nav-link.active { border-bottom-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.category-tab .categary-detail li.active a { color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.btn-normal:hover { color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body');
    window.$('<style>.nearby .store-list::-webkit-scrollbar-thumb { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    this.originalColor();
    
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.review_rating_module = parseFloat(JSON.parse(data).review_rating_module);
        this.show_review_rating = parseFloat(JSON.parse(data).show_review_rating);
        this.use_google_map = JSON.parse(data).use_google_map;
      }
    })

    window.$(document).ready(function(){
      window.$('<style>.nearby .shop-details.active-shop { background-color: '+localStorage.getItem("button_hover_color")+' !important }</style>').appendTo('body');
      window.$('<style>.nearby .shop-details.active-shop .media-body h3 {color: '+localStorage.getItem("css_button_customer_hover_font")+' !important;}</style>').appendTo('body');
      window.$('<style>.map-btn{color:'+localStorage.getItem("button_default_color")+' !important}</style>').appendTo('body');
    })
  },
  updated(){
    window.$('.right-skyblue svg polygon').css({ fill: localStorage.getItem("button_default_color")});
    this.avatarBackgroundColor = this.cancelReOrderButton.backgroundColor;
    this.avatarTextColor = this.cancelReOrderButton.color;
    window.$('.search-page .avatar table tbody tr td').css({ color: this.avatarTextColor});
    window.$('<style>.map-row .avatar { background-color: '+localStorage.getItem("button_default_color")+' !important }</style>').appendTo('body');
    window.$('<style>.map-row .avatar table tbody tr td{ color: '+localStorage.getItem("css_button_customer_default_font")+' !important }</style>').appendTo('body');
    window.$('<style>.map-row .avatar:hover { background-color: '+localStorage.getItem("css_button_customer_default_font")+' !important }</style>').appendTo('body');
  },
  methods: {
    strippedItemname: function(value) {
      if (value.length > 17) {
        return value.slice(0,15) + "...";
      }
      return value;
    },
    toggleInfoWindow(markerObject,index){
      this.selected_active_store = index;
      this.map_center_latitude = parseFloat(this.location_addresses[index].latitude);
      this.map_center_longitude = parseFloat(this.location_addresses[index].longitude);
      this.infowindow.lat = parseFloat(this.location_addresses[index].latitude);
      this.infowindow.lng = parseFloat(this.location_addresses[index].longitude);
      this.selected_location_name = this.location_addresses[index].store_name;
      
      window.$('.store-list').scrollTop(0)
      window.$('.store-list').scrollTop(150*(index))

      this.window_open = true;
    },
    ViewStore(storeId){
      this.$router.push({ path: `/shopdetail?storeid=${storeId}` });
    },
    FocusStore(storeId,index){
      if (this.location_addresses != null && this.location_addresses != undefined)
      {
        this.selected_location_name = this.location_addresses[index].store_name;
        this.map_center_latitude = parseFloat(this.location_addresses[index].latitude);
        this.map_center_longitude = parseFloat(this.location_addresses[index].longitude);
        this.selected_active_store = index;
        this.infowindow.lat = parseFloat(this.location_addresses[index].latitude);
        this.infowindow.lng = parseFloat(this.location_addresses[index].longitude);
        this.window_open = true;    
      }
    },
    showGridView(){
      this.showGridViewAfterSearch = true;
      this.showMapViewAfterSearch = false;
    },
    showMapView(){
      this.showGridViewAfterSearch = false;
      this.showMapViewAfterSearch = true;
    },
    shopRedirection(storeid,itemcount)
    {
      if(itemcount == "0")
      {
        localStorage.removeItem("home-search")
      }
      this.$router.push({ path: `/shopdetail?storeid=${storeid.toString()}` });
    },
    getStoreByCategory(category){
      this.category.shop_category_id = category.category_id;
      this.category.shop_category_name = category.category_name;
      this.category.type = "category";
      localStorage.setItem("selected-category", JSON.stringify(this.category));
      this.$router.push({ path: `/search-category?categoryid=${this.category.shop_category_id.toString()}` });
    },
    getActmenu(namekey, menuid, mainid) {
      if (namekey == "store_type") {
        let a = window.$("#new" + mainid + menuid).attr("class");
        if (a != undefined && a == "active") {
          if (this.storetypearr.length == 0) {
            this.storetypearr = [];
          } else {
            this.storetypearr.splice(menuid, 1);
          }
          window.$("#new" + mainid + menuid).removeAttr("class");
        } else {
          this.storetypearr.push(menuid);
          window.$("#new" + mainid + menuid).attr("class", "active");
        }
      } else if (namekey == "delivery_type") {
        let a = window.$("#new" + mainid + menuid).attr("class");
        if (a != undefined && a == "active") {
          if (this.deliveryarr.length == 0) {
            this.deliveryarr = [];
            window.$("#new" + mainid + menuid).removeAttr("class");
          } else {
            if(this.deliveryarr.length != 1)
            {
            this.deliveryarr.splice(menuid, 1);
            window.$("#new" + mainid + menuid).removeAttr("class");
            }
          }
          
        } else {
          this.deliveryarr.push(menuid);
          window.$("#new" + mainid + menuid).attr("class", "active");
        }
      } else if (namekey == "online_order") {
          let a = window.$("#new" + mainid + menuid).attr("class");
          if (a != undefined || a == "active") {
            if (this.onlineordrarr.length == 0) {
              this.onlineordrarr = [];
            } else {
              this.onlineordrarr.splice(menuid, 1);
            }

            window.$("#new" + mainid + menuid).removeAttr("class");
          } else {
            this.onlineordrarr.push(menuid);

            window.$("#new" + mainid + menuid).attr("class", "active");
          }
      }
    },
    pstGetSearch() {
        document.body.classList.remove('top-menu-add-padding');
        document.body.classList.remove('top-navbar');
        this.custadvncenwsearch.search_query = this.searchqry;
        if(this.searchqry != ""){
				localStorage.setItem("home-search",this.searchqry)
			}
        this.custadvncenwsearch.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
        this.custadvncenwsearch.lat = JSON.parse(localStorage.getItem("location_details")).lat;
        this.custadvncenwsearch.long = JSON.parse(localStorage.getItem("location_details")).lng;

        this.custadvncenwsearch.store_type = this.storetypearr.length != 0 ? this.storetypearr.join(",") : "";
        this.custadvncenwsearch.delivery_type = this.deliveryarr.length != 0 ? this.deliveryarr.join(",") : "";
        if(this.custadvncenwsearch.delivery_type == "0,1")
        {
          this.custadvncenwsearch.delivery_type="";
        }
        this.custadvncenwsearch.online_order = this.onlineordrarr.length != 0 ? this.onlineordrarr.join(",") : "";

        this.loading = true;
        this.storelist = "";
        var _this=this;

        var prelogin_pincode = localStorage.getItem("config_customer_pincode");
        var prelogin_area_id = localStorage.getItem("config_area_id");
        var postlogin_pincode = localStorage.getItem("user")!=null ? JSON.parse(localStorage.getItem("user")).zipcode : '';
        var postlogin_area_id = localStorage.getItem("user")!=null ? JSON.parse(localStorage.getItem("user")).area_id : '';
        var customer_location_type = localStorage.getItem("customer_location_type");

        if(this.location_hash == '#favorite-store'){
        this.search_result_name = this.$route.query.title!='' && this.$route.query.title!=null? this.$route.query.title: 'Favorites';
        this.favrtebody.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
        this.favrtebody.is_favourite = 1;
        this.favrtebody.lat = JSON.parse(localStorage.getItem("location_details")).lat;
        this.favrtebody.long = JSON.parse(localStorage.getItem("location_details")).lng;
        this.favrtebody.area_id = (this.userprofile != null) ? (customer_location_type == 2 && postlogin_area_id!=null? parseInt(postlogin_area_id):'') : (customer_location_type == 2 && prelogin_area_id!=null? parseInt(prelogin_area_id):'');
        this.favrtebody.pincode = (this.userprofile != null) ? (customer_location_type == 3 && postlogin_pincode!=null? parseInt(prelogin_pincode):'') : (customer_location_type == 3 && prelogin_pincode!=null? parseInt(prelogin_pincode):'');
        _this.markers =[];
        this.$store
          .dispatch("getcategrywisesearch", this.favrtebody)
          .then(response => {
            this.storelist = response;
            if (response.status == 1 && response.data != undefined)
            {
              response.data.forEach(function(item){
                let marker = { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)};
                _this.markers.push({ position: marker });
                _this.location_addresses.push(item)
              })
              if(_this.location_addresses != null && _this.location_addresses != undefined)
              {
                this.map_center_latitude = _this.location_addresses[0].latitude;
                this.map_center_longitude = _this.location_addresses[0].longitude;
              }
            }
            
            if(this.searchqry != "" && this.searchqry != null && response.data != undefined)
            {
             if(response.data.filter(x=>x.store_name.toLowerCase().trim() == this.searchqry.toLowerCase().trim()).length > 0)
             {
               localStorage.removeItem("home-search")
             }
            }
            this.loading = false;
          });          
        } else if(this.location_hash == '#nearby'){   
        
        this.search_result_name = this.$route.query.title!='' && this.$route.query.title!=null? this.$route.query.title: 'Nearby';
        this.favrtebody.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
        this.favrtebody.near_by = 1;
        this.favrtebody.lat = JSON.parse(localStorage.getItem("location_details")).lat;
        this.favrtebody.long = JSON.parse(localStorage.getItem("location_details")).lng;
        this.favrtebody.area_id = (this.userprofile != null) ? (customer_location_type == 2 && postlogin_area_id!=null? parseInt(postlogin_area_id):'') : (customer_location_type == 2 && prelogin_area_id!=null? parseInt(prelogin_area_id):'');
        this.favrtebody.pincode = (this.userprofile != null) ? (customer_location_type == 3 && postlogin_pincode!=null? parseInt(prelogin_pincode):'') : (customer_location_type == 3 && prelogin_pincode!=null? parseInt(prelogin_pincode):'');
        _this.markers =[];
        this.$store
          .dispatch("getcategrywisesearch", this.favrtebody)
          .then(response => {
            this.storelist = response;
            if (response.status == 1 && response.data != undefined)
            {
              response.data.forEach(function(item){
                let marker = { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)};
                _this.markers.push({ position: marker });
                _this.location_addresses.push(item)
              })
              if(_this.location_addresses != null && _this.location_addresses != undefined)
              {
                this.map_center_latitude = _this.location_addresses[0].latitude;
                this.map_center_longitude = _this.location_addresses[0].longitude;
              }
            }
            
            if(this.searchqry != "" && this.searchqry != null && response.data != undefined)
            {
             if(response.data.filter(x=>x.store_name.toLowerCase().trim() == this.searchqry.toLowerCase().trim()).length > 0)
             {
               localStorage.removeItem("home-search")
             }
            }
            this.loading = false;
          });          
        }
        else{
        this.$store
          .dispatch("getadvancenewserchlist", this.custadvncenwsearch)
          .then(response => {
            this.storelist = response;
            if (response.data != undefined)
            {
              response.data.forEach(function(item){
                let marker = { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)};
                _this.markers.push({ position: marker });
                _this.location_addresses.push(item)
              })
              if(_this.location_addresses != null && _this.location_addresses != undefined)
              {
                this.map_center_latitude = _this.location_addresses[0].latitude;
                this.map_center_longitude = _this.location_addresses[0].longitude;
              }
            }
            
            if(this.searchqry != "" && this.searchqry != null && response.data != undefined)
            {
             if(response.data.filter(x=>x.store_name.toLowerCase().trim() == this.searchqry.toLowerCase().trim()).length > 0)
             {
               localStorage.removeItem("home-search")
             }
            }
            this.loading = false;
          });
        }
    },
    getAdvnceFltrList() {
      this.$store.dispatch("getadvancefiltrlist").then(response => {
        this.advncefiltrlist = JSON.parse(JSON.stringify(response));
        if(this.advncefiltrlist.status == 1){
          if(localStorage.getItem("user") == null){
            var advncefiltrlist =  this.advncefiltrlist.data.filter(function(item) {
              if (item.key != "store_type") return item;
            });
            this.advncefiltrlist.data = advncefiltrlist;
          }
        }
      });
    }
  }
};
</script>